<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="操作时间">
                <a-range-picker @change="onDateChange" v-model="defaultDate"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam['order_num']"/>
              </a-form-item>
            </a-col>
            <!-- <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="进仓编号">
                <a-input v-model="queryParam['%wms_in_num']"/>
              </a-form-item>
            </a-col> -->
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户委托">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="内部编号">
                <a-input v-model="queryParam['%internal_num']"/>
              </a-form-item>
            </a-col> -->
            <!-- <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model="queryParam['%box_items']"/>
              </a-form-item>
            </a-col> -->
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="状态">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择状态"
                  style="width: 100%"
                  v-model="queryParam.store_status"
                >
                  <a-select-option :key="2">待处理</a-select-option>
                  <a-select-option :key="3">已处理</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="操作人员">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择操作人员"
                  style="width: 100%"
                  ref="operator_name"
                  v-model="queryParam.operator_id"
                >
                  <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :customRow="
          (record, index) => {
            return {
              style: {
                background: record.store_status === 2 ? '#f8fadf' : '#e7ffec'
              },
              on: {
                dblclick: (event) => {
                  this.handleEdit(record)
                }
              }
            }
          }
        "
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getCustomer } from '@/api/common'
import { filterOption } from '@/utils/util'
import { getWmsInBusinsessPage, getWmsDataOption } from '@/api/wms'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      queryParam: {},
      defaultDate: [moment().startOf('month'), moment().endOf('month')],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 80,
          align: 'center'
        },
        {
          title: '作业编号',
          dataIndex: 'serial_num'
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name'
        },
        {
          title: '状态',
          dataIndex: 'store_status',
          width: 80,
          customRender: (text) => {
            return text && text === 3 ? '已反馈' : '待处理'
          }
        },
        {
          title: '入库时间',
          dataIndex: 'store_date',
          width: 120,
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作时间',
          dataIndex: 'warehouse_operate_date',
          width: 120,
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '入库总立方',
          dataIndex: 'warehouse_in_cube'
        },
        {
          title: '操作员',
          dataIndex: 'warehouse_operate_person_name'
        },
        {
          title: '录单员',
          dataIndex: 'creator',
          width: 100
        },
        {
          title: '最后修改人',
          dataIndex: 'updater',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      operatorOps: [],
      currentData: null,
      loadData: parameter => {
        return getWmsInBusinsessPage(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'CumsInList' && this.queryParam) {
        this.$nextTick(_ => {
          this.$refs.table.refresh()
        })
      }
    }
  },
  created() {
    getWmsDataOption('operator').then(v => {
      this.operatorOps = v
    })
    this.queryParam['enter_start_date'] = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
    this.queryParam['enter_end_date'] = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
  },
  methods: {
    moment,
    filterOption,
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.queryParam['customer_id'] = value.key
      this.customerOps = []
      this.fetching = false
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'CumsInCUR',
        params: record,
        query: {
          disabled: false
        }
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['enter_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_date'] = null
        this.queryParam['enter_end_date'] = null
      }
    }
  }
}
</script>

<style scoped>
.date-btns{
  width: 240px;
  padding-left: 0!important;
  padding-right: 0!important;
}
</style>
